@import url(https://fonts.googleapis.com/css2?family=Rufina&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap);
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

